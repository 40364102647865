import React from "react";
import { useTranslation } from "react-i18next";
import { main, tips } from './plan-moving.module.css';
import { internalContainer } from '../styles/utils.module.css';
import Layout from '../components/Layout';
import MovingTips from '../components/MovingTips';
import InternalSection from '../components/InternalSection';
import InternalSectionTitle from '../components/InternalSectionTitle';
import InternalBottom from '../components/InternalBottom';

export default () => {
    const { t } = useTranslation();

    const copy = t('planMoving', { returnObjects: true });

    return (
        <Layout>
            <InternalSection content={copy}>
                <div className={`${main} ${internalContainer}`}>
                    <div>
                        <InternalSectionTitle>{copy.sections[0].title}</InternalSectionTitle>
                        <div className={tips}>
                            {copy.sections[0].list.reduce((acc, item, index) => {
                                if (index < 10) {
                                    acc[0].push(item);
                                } else {
                                    acc[1].push(item);
                                }
                                return acc;
                            }, [[],[]]).map((column, index) => (
                                <div key={index}>
                                    <ul>
                                        {column.map((li, index) => (
                                            <li key={index}>
                                                <h6># {li.title} Tip: </h6>
                                                {li.paragraphs.map((p, index) => (
                                                    <p style={{ display: index === 0 && 'inline'}} key={index}><span>{p}</span></p>
                                                ))}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <MovingTips />
                    </div>
                    <InternalBottom></InternalBottom>
                </div>
            </InternalSection>
        </Layout>
    );
}
