import React, { useState } from  'react';
import { main, tipContainer, tipContainerImage, tipSelector } from './MovingTips.module.css';
import { useTranslation } from "react-i18next";
import { IconContext } from 'react-icons';
import { /* FaArrowAltCircleRight */ } from 'react-icons/fa';
import { findImageData } from '../utils/tools';
import useImages from '../hooks/useImages';

export default () => {
    const [state, setState] = useState({
        selectedTipIndex: 0
    });

    const { t } = useTranslation();

    const images = useImages();

    const copy = t(`home.movingTips`, { returnObjects: true });

    const selectTip = (selectedTipIndex) => {
        setState(prevState => ({ ...prevState, selectedTipIndex }));
    };

    return (
        <div className={main}>
            <div>
                <h3>{copy.title}</h3>
                <h2>{copy.subtitle}</h2>
            </div>
            <div>
                <div>
                    <IconContext.Provider value={{ size: '1.5em'}}>
                        <ul>
                            {copy.list.map((tip, index) => (
                                <li key={index}>
                                    <div
                                        className={tipSelector}
                                        tabIndex="0"
                                        role="button"
                                        onKeyPress={() => selectTip(index)}
                                        onClick={() => selectTip(index)}
                                        style={{
                                            background: state.selectedTipIndex === index ? 'var(--light-gray)' : '',
                                        }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    color: state.selectedTipIndex === index ? 'var(--red)' : '',
                                                }}
                                            >
                                                <tip.icon />
                                            </span>
                                            <p>{tip.title}</p>
                                        </div>
                                        <div>
                                            <span
                                                style={{
                                                    color: state.selectedTipIndex === index ? 'var(--red)' : '',
                                                }}
                                            >
                                                {/* <FaArrowAltCircleRight size="1em"/> */}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </IconContext.Provider>
                </div>
                <div>
                    {copy.list.map((tip, index) => {
                        const imageData = findImageData(images, tip.image);
                        return (
                            <div
                                key={index}
                                className={tipContainer}
                                style={{
                                    display: state.selectedTipIndex === index ? '' : 'none',
                                }}
                            >
                                <div className={tipContainerImage}>
                                    <img
                                        alt={tip.title}
                                        src={imageData ? imageData.resize.src : ''}
                                    />
                                </div>
                                <div>
                                    <h2>{tip.title}</h2>
                                    <div>
                                        <span>{tip.content}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
